import React from 'react';

export default () => {
	return (
		<tr>
			<th>Buy</th>
			<th>Name</th>
			<th>Essense</th>
			<th>Capacity</th>
			<th>Avail</th>
			<th>Cost</th>
			<th>Ref</th>
		</tr>
	);
};
