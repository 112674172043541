/* Populated by react-webpack-redux:action */
export const SET_FILTER = 'SET_FILTER';
export const ADD_SKILL = 'ADD_SKILL';
export const REMOVE_SKILL = 'REMOVE_SKILL';
export const STYLE = 'STYLE';
export const WEAPON_MODDING = 'WEAPON_MODDING';
export const MODDING_MULTI = 'MODDING_MULTI';
export const DEMODDING_MULTI = 'DEMODDING_MULTI';
export const MODDING_CAPACITY = 'MODDING_CAPACITY';
export const DEMODDING_CAPACITY = 'DEMODDING_CAPACITY';
export const SELECT_GRADE = 'SELECT_GRADE';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODDING_VEHICLE = 'MODDING_VEHICLE';
export const DEMODDING_VEHICLE = 'DEMODDING_VEHICLE';
export const MODDING_DRONE = 'MODDING_DRONE';
export const DEMODDING_DRONE = 'DEMODDING_DRONE';
