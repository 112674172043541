const redux = require('redux');
const reducers = require('../reducers');

module.exports = function reduxStore(initialState) {
	const store = redux.createStore(reducers, initialState);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			// eslint-disable-next-line global-require
			const nextReducer = require('../reducers');
			store.replaceReducer(nextReducer);
		});
	}

	return store;
};
